import React from 'react'
// import { Helmet } from 'react-helmet'
import PageWrapper from '../../components/PageWrapper'
import GetStarted from '../../sections/landing1/GetStarted'
import { Section, Title, Text, Box, Button, SvgMode } from '../../components/Core'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { theme } from '../../utils'
import portfolioMgmt from '../../assets/image/svg/management-portfolio.svg'
import cryptoSync from '../../assets/image/svg/portfolioMgmt.svg'
import MetaData from '../../components/MetaData' 
import Link from '../../components/Link'
import ExchFeesTable from '../../components/Table/ExchFeesTable'
import { breakpoints } from '../../utils'
import HeaderConfig from '../../components/Header/HeaderConfig'


import ArrowRed from '../../assets/image/svg/arrowRed.inline.svg'
import ArrowGreen from '../../assets/image/svg/arrowGreen.inline.svg'
import ArrowWhite from '../../assets/image/svg/arrowWhite.inline.svg'
import ArrowBlack from '../../assets/image/svg/arrowBlack.inline.svg'


import TradeIniciatedLight from '../../assets/image/svg/trade-initiated-white.inline.svg'
import TradeExecutedLight from '../../assets/image/svg/trade-executed-white.inline.svg'
import MarkerTakerLight from '../../assets/image/svg/trade-marker-taker-white.inline.svg'

import TradeIniciatedDark from '../../assets/image/svg/trade-initiated-black.inline.svg'
import TradeExecutedDark from '../../assets/image/svg/trade-executed-black.inline.svg'
import MarkerTakerDark from '../../assets/image/svg/trade-marker-taker-black.inline.svg'

import TradeSettledLight from '../../assets/image/svg/trade-settled-white.inline.svg'
import TradeSettledDark from '../../assets/image/svg/trade-settled-black.inline.svg'

import styled from 'styled-components'

const ImageShadow = styled(Box)`
  img {
    border-radius: 0px;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 52px 42px ${theme.colors.shadow}`});
  }
`
const ListItem = styled.li`
  font-size: 21px;
  font-weight: 300;
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 8px !important;
`
const ArrowRedStyled = styled(ArrowRed)`
  width: 150px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 120px;
    margin-left: 3px;
  }
`
const TradingPotencialStyled = styled.div`
  .display-web {
    display: block !important;
    p {
      max-width: 200px;
      line-height: 21px;
      &.fees {
        max-width: 130px;
      }
    }
  }

  .display-mob {
    display: none !important;
  }

  @media (max-width: 1100px) {
    .display-web {
      display: none !important;
    }

    .display-mob {
      display: flex !important;
    }

    .arrow-container {
      display: flex;
      flex-direction: row;

      &_inner {
        flex: 1;
        flex-direction: column;
      }

      .row-item {
        flex: 1;
        flex-direction: column;
        margin-bottom: 20px;

        &:not(:first-child) {
          margin-bottom: 25px;
        }

        .arrow-container {
          flex-direction: column;
        }

        &_col {
          width: 100%;
          margin-top: 10px;
        }
      }

      .text-container {
        height: auto !important;
      }
    }

    .row-item-inner {
      height: auto !important;
    }

    .arrow-container-inner {
      height: auto;
      position: relative;
      margin-bottom: 22px;

      &.custom {
        height: auto !important;
      }

      p {
        max-width: 200px;
      }

      svg {
        top: 100px;
        left: 0;
        position: relative;
        transform: translate(-37%, 30%) rotate(90deg);
        margin: 10px 0;
        position: absolute;
      }
    }

    .arrow-container-inner.short {
      height: 85px;
      svg {
        top: 0;
        width: 100%;
      }
    }

    .arrow-container-inner.large {
      height: 277px;
    }
  }

  @media (max-width: 800px) {
    .custom-title {
      font-size: 18px;
    }
  }
`

const Lp3 = () => {
  const { t, ready } = useTranslation(['marketingLps', 'helloCrypto', 'pricing'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const sectionBackgroundDark = userDarkMode
    ? theme.colors.black
    : theme.colors.lightGray


  const i18nLang = HeaderConfig().i18nLang()
  const metaData = HeaderConfig().metaData
  const langs = metaData.site.siteMetadata.languages
  let lang = HeaderConfig().setLang(langs, i18nLang)

  const textColor = userDarkMode ? theme.colors.light : theme.colors.dark

  const textStyle = {
    color: userDarkMode ? theme.colors.bg : theme.colors.dark,
    color: userDarkMode ? theme.colors.bg : theme.colors.dark,
    color: userDarkMode ? theme.colors.bg : theme.colors.dark,
    fontSize: '16px',
  }


  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>

        {/* Trade with transparency : Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'} className="mt-5" pb={[5, null, null, 5]} >
          <Container>
            <Row className="pb-4">
              <Col lg="4">
                <Title 
                    variant="hero-home"
                    color={userDarkMode ? '#3986F7' : '#185398'}
                >
                    {t('tight')}
                </Title>
                <Text color={textColor} mb={24}>
                    {t('tradeBitcoin')}
                </Text>
                <ul>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('deepLiquidity')}
                    </ListItem>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('platform')}
                    </ListItem>
                    <ListItem 
                        style={{color: userDarkMode ? '#FAFAFA' : 'black'}} 
                        type="disc"
                    >
                        {t('manageAll')}
                    </ListItem>
                </ul>
                <a href={process.env.LS_SIGNUP}>
                  <Button>{t('helloCrypto.trial')}</Button>
                </a>
              </Col>
              <Col lg="8">
                <ExchFeesTable />
                <Text className="mt-5" color={textColor} mb={24}>
                    {t('riskFree')}
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section bg={userDarkMode ? theme.colors.dark : theme.colors.light} className="pb-5">
          <Container>
            <Row className="d-flex pb-4">
              {/* Left Side Content */}
              <Col lg="3" className="pr-lg-0 pl-lg-0">
                <Title color={textStyle.color}>
                  {t('pricing.tradingPotentialTitle')}
                </Title>
                <p style={{ color: textStyle.color, fontSize: '20px' }}>
                  {t('pricing.tradingPotentialDescription')}
                </p>
              </Col>

              {/* Right Side Content */}
              <Col lg="9" className="pr-lg-0 pl-lg-5">
                <TradingPotencialStyled>
                  <Col lg="12" className="arrow-container pr-lg-0 pl-lg-0 pr-0">
                    {/* First Row */}
                    <Row
                      lg="12"
                      className="arrow-container_inner d-flex align-items-start pb-4"
                    >
                      {/* Row Title */}
                      <Row className="d-flex pb-4">
                        <Text className="custom-title" color={textStyle.color}>
                          {t('pricing.lsSpreadTitle')}
                        </Text>
                      </Row>

                      {/* Row Items */}
                      {/* <Row lg="10" className="row-item d-flex align-items-center pb-4 mr-0 ml-0" style={{justifyContent: 'space-between' }}> */}
                      <Row
                        lg="12"
                        className="row-item w-100 d-flex align-items-center pb-4 mr-0 ml-0"
                      >
                        {/* Trade is initiated */}
                        <Col
                          lg="6"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              md="1"
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                              style={{ height: '70px' }}
                            >
                              <Col lg="2" xl="3" className="mr-0 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeIniciatedLight}
                                  Light={TradeIniciatedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="7"
                                xl="3"
                                className="display-mob arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('pricing.tradeInitiatedTitle')}
                                </Text>
                                <Text
                                  variant="small"
                                  style={{
                                    color: textStyle.color,
                                    lineHeight: '18px',
                                  }}
                                >
                                  <small>
                                    {t('pricing.tradeInitiatedDescription')}
                                  </small>
                                </Text>
                              </Col>

                              <Col
                                lg="9"
                                xl="3"
                                className="arrow-container-inner large ml-0 mr-0 pl-0 pr-0"
                              >
                                <ArrowGreen />
                              </Col>
                            </Row>

                            <Row
                              xl="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('pricing.tradeInitiatedTitle')}
                              </Text>
                              <Text
                                variant="small"
                                style={{
                                  color: textStyle.color,
                                  lineHeight: '18px',
                                }}
                              >
                                <small>
                                  {t('pricing.tradeInitiatedDescription')}
                                </small>
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is executed */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                              style={{ height: '70px' }}
                            >
                              <Col lg="3" className="mr-0 ml-0 pl-0 pr-0">
                                <SvgMode
                                  Dark={TradeExecutedLight}
                                  Light={TradeExecutedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="12"
                                className="display-mob row-item-inner pl-0 pr-0 ml-0 mr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('pricing.tradeExecutedTitle')}
                                </Text>
                                <Text
                                  variant="small"
                                  style={{ color: textStyle.color }}
                                >
                                  <small>
                                    {t('pricing.tradeExecutedDescription')}
                                  </small>
                                </Text>
                              </Col>

                              <Col
                                lg="7"
                                className="arrow-container-inner short d-flex align-items-center mr-0 ml-0 pl-0 pr-0"
                              >
                                <SvgMode
                                  width="240"
                                  Dark={ArrowWhite}
                                  Light={ArrowBlack}
                                  isDarkMode={userDarkMode}
                                  className="short-arrow"
                                />
                              </Col>
                            </Row>

                            <Row
                              xl="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('pricing.tradeExecutedTitle')}
                              </Text>
                              <Text
                                variant="small"
                                style={{ color: textStyle.color }}
                              >
                                <small>
                                  {t('pricing.tradeExecutedDescription')}
                                </small>
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is settled */}
                        <Col
                          lg="2"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              className="row-item-inner ml-0 mr-0 d-flex align-items-end"
                              style={{ height: '70px' }}
                            >
                              <Col lg="5" className="mr-1 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeSettledLight}
                                  Light={TradeSettledDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>
                            </Row>

                            <Col
                              lg="12"
                              className="display-web ml-0 mr-0 pr-0 pl-0 mt-3 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('pricing.tradeSettledTitle')}
                              </Text>
                              <Text
                                variant="small"
                                style={{
                                  color: textStyle.color,
                                  lineHeight: '18px',
                                }}
                              >
                                <small>
                                  {t('pricing.tradeSettledDescription')}
                                </small>
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>

                    {/* Second Row */}
                    <Row
                      lg="12"
                      className="arrow-container_inner d-flex align-items-start pb-4"
                    >
                      {/* Row Title */}
                      <Row className="d-flex align-items-center pb-4">
                        <Text className="custom-title" color={textStyle.color}>
                          {t('pricing.otherExchangesTitle')}
                        </Text>
                      </Row>

                      {/* Row Items */}
                      <Row
                        lg="11"
                        className="row-item d-flex align-items-center pb-4 mr-0 ml-0"
                      >
                        {/* Trade is initiated */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center w-100"
                              style={{ height: '70px' }}
                            >
                              <Col lg="2" xl="3" className="mr-0 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeIniciatedLight}
                                  Light={TradeIniciatedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="7"
                                xl="3"
                                className="display-mob arrow-container-inner custom ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('pricing.tradeInitiatedTitle')}
                                </Text>
                              </Col>

                              <Col
                                lg="9"
                                xl="3"
                                className="arrow-container-inner short ml-0 mr-0 pl-0 pr-0"
                              >
                                <ArrowRedStyled />
                              </Col>
                            </Row>

                            <Row
                              lg="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('pricing.tradeInitiatedTitle')}
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Maker/taker fees added to trade */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                            >
                              <Col lg="3" className="mr-0 ml-0 pl-0 pr-0">
                                <SvgMode
                                  Dark={MarkerTakerLight}
                                  Light={MarkerTakerDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="7"
                                xl="3"
                                className="display-mob arrow-container-inner custom ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text
                                  style={{
                                    color: textStyle.color,
                                    fontSize: textStyle.fontSize,
                                    lineHeight: '22px',
                                    maxWidth: '150px',
                                  }}
                                >
                                  {t('pricing.tradeMakerTitle')}
                                </Text>
                              </Col>

                              <Col
                                lg="7"
                                className="arrow-container-inner short  ml-0 mr-0 pl-0 pr-0"
                              >
                                <ArrowRedStyled />
                              </Col>
                            </Row>
                            <Row
                              lg="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column w-100"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle} className="fees">
                                {t('pricing.tradeMakerTitle')}
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is executed */}
                        <Col
                          lg="3"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              lg="12"
                              className="row-item-inner ml-0 mr-0 pl-0 pr-0 d-flex align-items-center"
                              style={{ height: '70px' }}
                            >
                              <Col lg="3" className="mr-0 ml-0 pl-0 pr-0">
                                <SvgMode
                                  Dark={TradeExecutedLight}
                                  Light={TradeExecutedDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>

                              <Col
                                lg="12"
                                className="display-mob row-item-inner pl-0 pr-0 ml-0 mr-0 mt-2 d-flex flex-column"
                                style={{ height: '80px' }}
                              >
                                <Text style={textStyle}>
                                  {t('pricing.tradeExecutedTitle')}
                                </Text>
                              </Col>

                              <Col
                                lg="7"
                                className="arrow-container-inner short d-flex align-items-center mr-0 ml-0 pl-0 pr-0"
                              >
                                <SvgMode
                                  width="240"
                                  Dark={ArrowWhite}
                                  Light={ArrowBlack}
                                  isDarkMode={userDarkMode}
                                  className="short-arrow"
                                />
                              </Col>
                            </Row>

                            <Row
                              xl="12"
                              className="display-web arrow-container-inner ml-0 mr-0 pl-0 pr-0 mt-2 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('pricing.tradeExecutedTitle')}
                              </Text>
                            </Row>
                          </Row>
                        </Col>

                        {/* Trade is settled */}
                        <Col
                          lg="2"
                          className="row-item_col ml-0 mr-0 pl-0 pr-0"
                        >
                          <Row className="ml-0 mr-0 d-flex arrow-container">
                            <Row
                              className="row-item-inner ml-0 mr-0 d-flex align-items-end"
                              style={{ height: '70px' }}
                            >
                              <Col lg="5" className="mr-1 ml-0 pl-0">
                                <SvgMode
                                  Dark={TradeSettledLight}
                                  Light={TradeSettledDark}
                                  isDarkMode={userDarkMode}
                                />
                              </Col>
                            </Row>

                            <Col
                              lg="12"
                              className="display-web ml-0 mr-0 pr-0 pl-0 mt-3 d-flex flex-column"
                              style={{ height: '80px' }}
                            >
                              <Text style={textStyle}>
                                {t('pricing.tradeSettledTitle')}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </TradingPotencialStyled>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* Trade often for less: Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'}  >
          <Container>
            <Row className="pb-4">
              <Col lg="6">
                <Title variant="highlight" color={textColor}>
                    {t('onePlatform')}
                </Title>
                <Text color={textColor} mb={24}>
                    {t('ruleAll')}
                </Text>
                <Text color={textColor} mb={24}>
                    {t('streamline')}
                </Text>
                <Link to={`/${lang}/portfolio-management/`}>
                  <Button>{t('helloCrypto.cryptoSYNCbtn')}</Button>
                </Link>
              </Col>
              <Col lg="6">
              <ImageShadow
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <img
                    width="100%"
                    height="auto"
                    alt=""
                    className="img-fluid"
                    src={portfolioMgmt}
                  />
                </ImageShadow>
              </Col>
            </Row>
          </Container>
        </Section>
        <GetStarted />
      </PageWrapper>
    </>
  )
}
export default Lp3